































































































































import { Vue, Component, Prop, Ref, Watch } from 'vue-property-decorator'
import { CapaRegraImposto, RegraDeAplicacao, RegraPorEstadoOrigem } from '@/models'
import SeletorDeOrigem from '@/components/fiscal/SeletorDeOrigem.vue'
import SeletorDeRegimeFiscal from '@/components/fiscal/SeletorDeRegimeFiscal.vue'
import CamposDeImposto from './shareds/CamposDeImposto.vue'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import EdicaoGenerica from '@/components/layout/EdicaoGenerica.vue'
import TelaDeRegraPorEstado from './TelaDeRegraPorEstado.vue'
import DialogoDeRegraDeAplicacao from './DialogoDeRegraDeAplicacao.vue'
import { nextTick } from '@/shareds/utils'
import {
	SaveRegraDeImpostoUseCase,
	FindOrigemUseCase,
	FindRegraDeImpostoUseCase, 
	DeleteRegraDeImpostoUseCase,
	FindRegraDeAplicacaoUseCase,
	SaveRegraDeAplicacaoUseCase,
} from '@/usecases'
import { obrigatorio } from '@/shareds/regras-de-form'
import axios, { CancelTokenSource } from 'axios'
import { DataOptions } from 'vuetify'
import DataTableDeCrud from '@/components/ui/DataTableDeCrud.vue'
import Confirmacao from '@/components/ui/Confirmacao.vue'
import DialogoDeCapaDeRegraFiscal from '@/views/application/configuracoes/fiscal/DialogoDeCapaDeRegraFiscal.vue'
import { sortConverter } from '@/shareds/paginacao'

@Component({
	components: {
		SeletorDeOrigem,
		SeletorDeRegimeFiscal,
		EdicaoGenerica,
		DataTableDeCrud,
		TelaDeRegraPorEstado,
		DialogoDeRegraDeAplicacao,
		CamposDeImposto,
		Confirmacao,
		DialogoDeCapaDeRegraFiscal,
	},
})
export default class TelaDeEdicaoDeRegraDeAplicacao extends Vue {
	@Prop({ type: String }) id!: string
	@Prop({ type: Boolean }) novo!: boolean
	@Prop({ type: String }) idRegraAplicacao!: string
	@Ref() dialogoDeRegraPorEstado!: TelaDeRegraPorEstado
	@Ref() edicaoGenerica!: EdicaoGenerica<CapaRegraImposto>
	@Ref() dataTablePaginado!: DataTableDeCrud
	@Ref() confirmacaoDeDeletarEstado!: Confirmacao
	@Ref() dialogoDeEdicao!: DialogoDeCapaDeRegraFiscal

	obrigatorio = obrigatorio

	salvandoRegraDeEstado = false

	headers = [
		{ text: 'Estado Origem', value: 'estado', sortable: true },
	]

	paginacao: DataOptions = {
		page: 0,
		itemsPerPage: 50,
		sortBy: [],
		sortDesc: [],
		groupBy: [],
		groupDesc: [],
		multiSort: false,
		mustSort: false,
	}

	estados: string[] = []

	findRegraDeImpostoUseCase = new FindRegraDeImpostoUseCase()
	saveRegraDeImpostoUseCase = new SaveRegraDeImpostoUseCase()
	deleteRegraDeImpostoUsecase = new DeleteRegraDeImpostoUseCase()
	findOrigemUsecase = new FindOrigemUseCase()
	findRegraDeAplicacaoUseCase = new FindRegraDeAplicacaoUseCase()
	saveRegraDeAplicacaoUseCase = new SaveRegraDeAplicacaoUseCase()

	regrasPorEstadoOrigem: RegraPorEstadoOrigem[] = []
	regra: CapaRegraImposto | null = null
	versaoAnterior: CapaRegraImposto | null = null
	cancelToken: CancelTokenSource | null = null
	regraDeAplicacao: RegraDeAplicacao | null = null

	regrasDeAplicacao: RegraDeAplicacao[]  = []

	carregando = true
	loadingItens = false
	salvando = false
	salvo = false
	loadingCopia = false

	regraSelecionada = ''
	removerRegraAplicacao = false

	descricaoRegraAplicacao = ''
	tipoDeClienteRegraAplicacao = ''

	erro: null | Error = null

	regraDeAplicacaoIdSelecionado: string | null = null

	created() {
		this.carregar()
	}

	voltarParaTelaAnterior() {
		this.$router.back();
	}

	async carregar() {
		try {
			this.carregando = true

			this.regra = await this.findRegraDeImpostoUseCase.getRegra(this.id)

			this.buscarRegraDeAplicacao()

			nextTick().then(() => {
				this.versaoAnterior = this.versaoAnterior = JSON.parse(JSON.stringify(this.regra))
			})
		} catch (error) {
			AlertModule.setError(error)
		} finally {
			this.carregando = false
		}
	}

	async abrirTela(indice: number) {
		if (!this.regra) return
		this.$router.push({
			name: 'Edição de Regra por Estado',
			params: {
				ufOrigem: this.estados[indice], 
				idRegraAplicacao: this.idRegraAplicacao,
				idRegraImposto: this.id,
				regimeFiscal: this.regra.regimeFiscal || 'Simples Nacional',
			},
		})
	}

	async buscarRegraDeAplicacao() {
		if (this.cancelToken) this.cancelToken.cancel()
		try {
			if (!this.id) return
			this.loadingItens = true
			this.cancelToken = axios.CancelToken.source()

			this.regraDeAplicacao = await this.findRegraDeAplicacaoUseCase.getRegra(this.idRegraAplicacao)
			
			this.descricaoRegraAplicacao = this.regraDeAplicacao.descricao || ''
			
			this.tipoDeClienteRegraAplicacao = this.regraDeAplicacao.tipoDeCliente || ''

			this.estados = await this.findRegraDeAplicacaoUseCase.getEstadosOrigemPorRegraAplicacao(this.idRegraAplicacao)

			this.cancelToken = axios.CancelToken.source()
			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}
			const sorted = sortConverter(this.paginacao)
			const pageItens = await this.findRegraDeAplicacaoUseCase
				.findByRegraImposto(this.id,{
					sort: [...sorted],
					page: this.paginacao.page - 1 || 0,
					size: this.paginacao.itemsPerPage,
				}, axiosConfig)

			this.regrasDeAplicacao = pageItens.content
		} catch (e) {
			if (axios.isCancel(e)) return
			AlertModule.setError(e)
		} finally {
			this.loadingItens = false
		}
	}

	async copiarSelecionado() {
		try {
			this.loadingCopia = true

			if (this.regraDeAplicacao?.id && this.regraDeAplicacaoIdSelecionado) {
				this.regraDeAplicacao = await this.saveRegraDeAplicacaoUseCase.copiar(this.regraDeAplicacao?.id, this.regraDeAplicacaoIdSelecionado)
				AlertModule.setSuccess(`Regra copiada com sucesso`)
			} else {
				AlertModule.setError('Nenhuma Regra de Aplicação selecionada, verifique!')
			}
		} catch(error) {
			AlertModule.setError(error)
		} finally {
			this.loadingCopia = false
		}
	}

	get estadosFormatados(): object[] {

		let estadosObject = [] as object[]

		if (this.estados) {
			estadosObject = this.estados.map(uf => {
				return { 
					estado: uf,
				}
			})
		}

		return estadosObject
	}

	@Watch('paginacao', { deep: true })
	onChangePaginacao = this.buscarRegraDeAplicacao

	getOperacao(operacao: string) {
		return operacao === 'DEVOLUCAO'
			? 'Devolução'
			: 'Saída'
	}
}

