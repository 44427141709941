



































import { Vue, Component, Ref, Prop, Watch, PropSync } from 'vue-property-decorator'
import { RegraDeImposto, RegimeFiscal, Page, CapaRegraImposto } from '@/models'
import { FindRegraDeImpostoUseCase } from '@/usecases'
import axios, { CancelTokenSource } from 'axios'
import { Pageable } from '@/models/Pageable'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'

@Component
export default class SeletorDeRegraFiscal extends Vue {
	@Prop({ type: String, required: false }) regimeFiscal?: RegimeFiscal
	@PropSync('value', { type: Object, default: null }) regraFiscal!: RegraDeImposto | null
	@Ref() wrapped!: Vue & HTMLInputElement & { isMenuActive: boolean }
	regras: CapaRegraImposto[] = []
	findRegrasDeImpostoUseCase = new FindRegraDeImpostoUseCase()
	busca = ''
	pagina: Page<CapaRegraImposto> | null = null

	regrasCarregadas = 0
	carregouTodasAsRegras = false
	carregando = false
	cancelToken: CancelTokenSource | null = null

	async created() {
		await this.buscar()
	}

	async buscar(paginavel: Pageable = {}) {
		if (this.cancelToken) this.cancelToken.cancel()
		try {
			this.carregando = true
			this.cancelToken = axios.CancelToken.source()

			const axiosConfig = {
				cancelToken: this.cancelToken.token,
			}

			this.pagina = await this.findRegrasDeImpostoUseCase.find({
				descricao: this.busca || undefined,
				page: this.busca ? -1 : (paginavel.page || 0),
				size: paginavel.size || 10,
			}, axiosConfig )

			this.pagina.content.forEach(regra => {
				this.regras.push(regra)
			})

			this.regrasCarregadas += this.regras.length
			this.carregouTodasAsRegras = this.regrasCarregadas >= this.pagina.totalElements
			this.carregando = false
		} catch (e) {
			if (axios.isCancel(e)) return
			AlertModule.setError(e)
		} finally {
			this.carregando = false
		}
	}

	get regrasFiltradas() {
		const regrasFiltradas = this.regimeFiscal
			? this.regras.filter(
				({ regimeFiscal }) => regimeFiscal === this.regimeFiscal,
			)
			: this.regras
		const regras = this.regraFiscal
			? [...regrasFiltradas, this.regraFiscal]
			: [ ...regrasFiltradas ]

		return regrasFiltradas.some(regra => regra.id === this.regraFiscal?.id)
			? regrasFiltradas
			: regras
	}

	public focus() {
		this.wrapped.focus()
	}

	@Watch('busca')
	onChangeBusca() {
		this.regrasCarregadas = 0
		this.regras = []
		this.carregouTodasAsRegras = false
		this.buscar()
	}

	limpaFiltros() {
		this.carregouTodasAsRegras = false
		this.regrasCarregadas = 0
	}
}
