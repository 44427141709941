











import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { VAutocomplete, VTextField } from 'vuetify/lib'
import { sortByCodigo, findCSTs } from '@/shareds/fiscal'
import { CST, TipoDeImposto } from '@/models'

@Component({
	components: {
		VAutocomplete,
		VTextField,
	},
})
export default class SeletorDeCst extends Vue {
	@Prop({ type: String }) tipo!: TipoDeImposto

	cstImposto: CST[] = []

	async created() {
		this.cstImposto = await findCSTs(this.tipo)
	}

	get csts() {
		return this.cstImposto.sort(sortByCodigo)
	}

	@Watch('tipo')
	onChangeTipo(tipoAnterior, tipoAtual) {
		if (tipoAnterior && tipoAnterior !== tipoAtual) {
			this.$emit('input', null)
		}
	}
}
