


















































import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import { TipoDeRecurso, TipoDeOperacao } from '@/models/Recurso'
import UserLoginStore from '../../store/vuex/authentication/UserLoginStore'
import { montarOperacaoDeRecurso } from '../../shareds/permissoes-shareds'
import { nextTick } from '@/shareds/utils';
import Confirmacao from '@/components/ui/Confirmacao.vue'

type Extends<T, U extends T> = U;

@Component({
	components: {
		Confirmacao,
	},
})
export default class EdicaoGenerica<DataType> extends Vue  {
	@Prop({ type: String, required: false }) titulo?: string
	@Prop({ type: String, default: 'Salvar' }) tituloSalvar!: string
	@Prop({ type: Object, default: () => null }) data!: DataType | null
	@Prop({ type: Boolean, default: false }) salvando!: boolean
	@Prop({ type: Boolean, default: false }) carregando!: boolean
	@Prop({ type: Error, default: null }) erro!: null | Error
	@Prop({ type: String }) recurso!: TipoDeRecurso
	@Prop({ type: Boolean }) novo!: boolean
	@Ref() form!: HTMLFormElement
	@Prop({ type: Boolean, default: false }) disableValidation!: boolean
	@Prop({ type: Object, default: null}) versaoAnterior!: DataType | null
	@Ref() descartarAlteracoesEmFormulario!: Confirmacao

	get recursoNormalizado() {
		return this.recurso || this.$route.meta?.recurso
	}

	get novoNormalizado() {
		return this.novo || this.$route.params.novo
	}

	get operacao(): Extends<TipoDeOperacao, 'criar' | 'alterar'> {
		return this.novoNormalizado
			? 'criar'
			: 'alterar'
	}

	get podeEditar() {
		if (!this.recursoNormalizado) return true
		return UserLoginStore.temAutorizacao(
			montarOperacaoDeRecurso(this.operacao, this.recursoNormalizado),
		)
	}

	async submit() {
		if (!this.form.validate() && !this.disableValidation) return
		await nextTick()
		this.$emit('salvar', this.data)
	}

	get temAlteracao(){
		return JSON.stringify(this.versaoAnterior) !== JSON.stringify(this.data)
	}

	async validarTransicao() {
		return this.temAlteracao && !await this.descartarAlteracoesEmFormulario.mostrar()
	}
}

