


























































import { Vue, Component, PropSync, Prop } from 'vue-property-decorator'
import { Imposto, TipoDeImposto } from '@/models/fiscal/Imposto'
import SeletorDeImposto from '@/components/fiscal/SeletorDeImposto.vue'
import CampoDePercentual from '@/components/ui/CampoDePercentual.vue'
import SeletorDeCst from '@/components/fiscal/SeletorDeCst.vue'
import { RegimeFiscal } from '@/models'
import SeletorDeCsosn from '@/components/fiscal/SeletorDeCsosn.vue'
import SeletorDeRegraFiscal from '@/components/fiscal/SeletorDeRegraFiscal.vue'

@Component({
	components: {
		SeletorDeImposto,
		CampoDePercentual,
		SeletorDeCst,
		SeletorDeCsosn,
		SeletorDeRegraFiscal,
	},
})
export default class CamposDeImposto extends Vue {
	@PropSync('value') imposto!: Imposto
	@Prop({ type: Boolean, default: false }) fixo!: boolean
	@Prop({ type: Boolean, default: false }) esconderSeletor!: boolean
	@Prop({ type: String, default: null }) regimeFiscal!: RegimeFiscal

	$refs!: {
		campoDeDescricao: HTMLInputElement
	}


	focus() {
		this.$refs.campoDeDescricao.focus()
	}

	get componenteDeSeletorNoIcms() {
		return ['MEI', 'Simples Nacional'].includes(this.regimeFiscal) &&
		this.imposto.tipoDeImposto === 'ICMS'
			? 'SeletorDeCsosn'
			: 'SeletorDeCst'
	}

	get displayTipoDeImposto() {
		return ['MEI', 'Simples Nacional'].includes(this.regimeFiscal) &&
		this.imposto.tipoDeImposto === 'ICMS'
			? `CSOSN de ${this.imposto.tipoDeImposto}`
			: `CST de ${this.imposto.tipoDeImposto}`
	}

	temDoisSeletoresDeCst(tipo: TipoDeImposto) {
		return ['PIS', 'COFINS', 'IPI'].includes(tipo)
	}

}
