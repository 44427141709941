var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fit-content"},[_c('v-container',[_c('v-col',[_c('v-btn',{attrs:{"color":"primary","dark":"","absolute":"","left":"","icon":"","small":""},on:{"click":_vm.voltarParaTelaAnterior}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1)],1),_c('v-form',{on:{"submit":function($event){$event.preventDefault();return (function () { return _vm.$emit('salvar', _vm.regraPorEstado); }).apply(null, arguments)}}},[_c('v-col',[(_vm.regraPorEstado)?_c('v-card',[_c('v-card-title',{staticClass:"pb-1"},[_vm._v("Regra de Estado Origem - "+_vm._s(_vm.regraPorEstado.ufOrigem))]),_c('v-card-text',[_c('v-tabs',{attrs:{"show-arrows":"","dark":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.regrasPorEstado),function(regra){return _c('v-tab',{key:regra.id},[_vm._v(" "+_vm._s(regra.ufDestino)+" ")])}),1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.regrasPorEstado),function(regra,indice){return _c('v-tab-item',{key:regra.id},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-row',[_c('v-col',{attrs:{"md":"4"}},[_c('v-sheet',{staticClass:"pa-5"},[_c('v-switch',{attrs:{"inset":"","label":"Regra ativa"},model:{value:(regra.regraAtiva),callback:function ($$v) {_vm.$set(regra, "regraAtiva", $$v)},expression:"regra.regraAtiva"}})],1)],1),_c('v-col',{attrs:{"md":"4","offset-md":"4"}},[_c('h3',[_vm._v("Copiar regra para o estado destino:")]),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-row',[_c('v-select',_vm._g(_vm._b({attrs:{"items":_vm.estadosACopiar,"item-text":function (ref) {
	var id = ref.id;
	var ufDestino = ref.ufDestino;

	return ("" + ufDestino);
},"menu-props":{ top: true, offsetY: true },"label":"Estado Destino","item-value":"id"},model:{value:(_vm.estadoSelecionadoId),callback:function ($$v) {_vm.estadoSelecionadoId=$$v},expression:"estadoSelecionadoId"}},'v-select',_vm.$attrs,false),_vm.$listeners)),_c('v-col',[_c('v-btn',{attrs:{"disabled":_vm.loadingCopia,"loading":_vm.loadingCopia,"dark":""},on:{"click":function($event){return _vm.copiarParaSelecionado(regra)}}},[_vm._v(" Copiar ")])],1)],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('CampoDePercentual',{attrs:{"label":"FCP"},model:{value:(regra.percentualFCP),callback:function ($$v) {_vm.$set(regra, "percentualFCP", $$v)},expression:"regra.percentualFCP"}})],1),_c('v-col',{attrs:{"md":"4"}},[_c('v-sheet',{staticClass:"pa-5"},[_c('v-switch',{attrs:{"inset":"","label":"Reduzir ICMS da base de PIS e Cofins"},model:{value:(regra.reduzIcms),callback:function ($$v) {_vm.$set(regra, "reduzIcms", $$v)},expression:"regra.reduzIcms"}})],1)],1),_c('v-col',{attrs:{"md":"4"}},[_c('v-sheet',{staticClass:"pa-5"},[_c('v-switch',{attrs:{"inset":"","label":"Incluir valor do frete na base de cálculo"},model:{value:(regra.incluirFrete),callback:function ($$v) {_vm.$set(regra, "incluirFrete", $$v)},expression:"regra.incluirFrete"}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('header',[_vm._v("Impostos")]),_vm._l((regra.impostos),function(imposto){return _c('CamposDeImposto',{key:'campo-' + imposto.tipoDeImposto,attrs:{"value":imposto,"regimeFiscal":_vm.regimeFiscal,"fixo":""},on:{"input":function (imposto) {
	var _obj;

	return regra ? 
													(regra.impostos = Object.assign({}, regra.impostos,
														( _obj = {}, _obj[imposto.nome] = imposto, _obj ))) 
													:
													undefined;
}}})})],2),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-combobox',{attrs:{"readonly":true,"append-icon":""},model:{value:(_vm.icmsst),callback:function ($$v) {_vm.icmsst=$$v},expression:"icmsst"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('CampoDePercentual',{attrs:{"label":"MVA","persistent-hint":"","hint":"Margem de Valor Agregado"},model:{value:(regra.percentualMVA),callback:function ($$v) {_vm.$set(regra, "percentualMVA", $$v)},expression:"regra.percentualMVA"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"8"}}),_c('v-col',{attrs:{"cols":"12","sm":"2"}},[_c('v-text-field',{attrs:{"type":"text","label":"Código de Benefício"},model:{value:(regra.codigosDeBeneficio),callback:function ($$v) {_vm.$set(regra, "codigosDeBeneficio", $$v)},expression:"regra.codigosDeBeneficio"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('header',[_vm._v("Operações")]),_c('CamposDeTipoDeOperacaoFiscal',{model:{value:(_vm.regrasPorEstado[indice]),callback:function ($$v) {_vm.$set(_vm.regrasPorEstado, indice, $$v)},expression:"regrasPorEstado[indice]"}})],1)],1)],1)}),1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"ml-auto",attrs:{"type":"submit","text":"","color":"success","disabled":_vm.salvando,"loading":_vm.salvando},on:{"click":_vm.salvar}},[_vm._v("Salvar")])],1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }